import { useCallback, useState } from "react";
import { useSrcmApi } from "../../fetch-srcm-api";

const useSearchAbhyasiEMI = (storeKey) => {
  const { isFetching, data, errorMessage, updateParams } = useSrcmApi(
    storeKey,
    null
  );
  const [requested, setRequested] = useState(false);
  const [query, setValue] = useState("");

  const refresh = useCallback(
    (key, value) => {
      setRequested(true);
      setValue(value);
      updateParams({
        api: `/api/v2/abhyasis/`,
        client: "preceptorCandidateAppClient",
        methodParams: { [key]: value },
      });
    },
    [setRequested, updateParams]
  );

  const reset = useCallback(() => setRequested(false), [setRequested]);
  const noresult =
    data && data.count === 0
      ? `${query} not registered/found with any Profile`
      : null;
  const dataFetched =
    !isFetching && !errorMessage && requested && data && data.count > 0;
  // const unmasked = dataFetched && "abhyas_stage" in data.results[0];

  // hack to overcome the limitation of masked content in /abhyasis/search/ api
  // useEffect(() => {
  //   if (dataFetched && !unmasked) {
  //     updateParams({
  //       api: `/api/v2/abhyasis/`,
  //       methodParams: { id: data.results[0].id },
  //       client: "preceptorCandidateAppClient",
  //     });
  //   }
  // }, [unmasked, dataFetched, query, updateParams, data]);

  return {
    isFetching,
    result: dataFetched ? data.results[0] : null,
    errorMessage: !isFetching ? errorMessage || noresult : null,
    refresh,
    reset,
  };
};
export default useSearchAbhyasiEMI;
