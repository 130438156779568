import { formatSys2OdooDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";

const today = formatSys2OdooDate(new Date());

const formFields = [
  {
    name: "date_added",
    label: "Date Added",
    type: "date",
    props: { required: true, minDate: "2022-01-10", maxDate: today },
  },
  {
    name: "notes",
    label: "Notes / Remarks",
    type: "text",
    props: { multiline: true, rows: 6, required: true },
  },
];

const collection = "AbhyasiMentoring";
const initialQuery = ({ uid }) => ({
  collection,
  where: ["uid", "==", uid],
  orderBy: [["date_added", "desc"]],
});

const config = {
  formFields,
  collection,
  // firestorePath: "preceptorspecialsittings2022",
  initialQuery,
  cachingKey: "abmentoring",
  copyFromProfile: [
    "name",
    "abhyasiId",
    "srcm_group",
    "city",
    "country",
    "state",
  ],
  copyFromProfileMap: {
    name: "mentor_name",
    srcm_group: "mentor_center",
    abhyasiId: "mentor_abhyasi_id",
    city: "mentor_city",
    state: "mentor_state",
    country: "mentor_country",
    age: "mentor_age",
  },
  copyForMentee: [
    "name",
    "abhyasiId",
    "srcm_group",
    "city",
    "country",
    "age",
    "state",
    "gender",
  ],
  copyForMenteeMap: {
    name: "mentee_name",
    srcm_group: "mentee_center",
    abhyasiId: "mentee_abhyasi_id",
    city: "mentee_city",
    state: "mentee_state",
    country: "mentee_country",
    age: "mentee_age",
    gender: "mentee_gender",
  },
};
export default config;
