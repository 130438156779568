/** @jsx jsx */
import { jsx, Spinner, Text, Alert } from "theme-ui";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { containerStyles } from "sites-common/utils/fabricStyles";
import {
  DefaultButton,
  ActionButton,
  PrimaryButton,
  Stack,
  IconButton,
} from "office-ui-fabric-react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { noop } from "sites-common/utils/lodash";
import {
  doFormatShortDate,
  formatSys2OdooDate,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import {
  Table,
  TableCell,
  TableRow,
} from "sites-common/components/ui/DivTable";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import copyKeys from "sites-common/utils/copyKeys";
import PropTypes from "prop-types";
import useSearchAbhyasiEMI from "gatsby-plugin-hfn-profile/components/SrcmModels/useSearchAbhyasiEMI";
import { renderAbhyasiWithPhoto } from "gatsby-plugin-hfn-profile/components/SrcmEntities/Render/RenderAbhyasiDetails";
import {
  validateAbhyasiId,
  validateEmail,
  validatePhoneNumber,
} from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import { fget } from "gatsby-plugin-hfn-profile/components/Login";
import { showAlert } from "gatsby-plugin-hfn-profile/state/actions";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import useZoneForCenter from "gatsby-plugin-hfn-profile/components/SrcmEntities/Render/useZoneForCenter";
import ConfirmButton from "gatsby-plugin-hfn-profile/components/ConfirmButton";
import config from "./config";
import strings from "./strings.json";

const today = formatSys2OdooDate(new Date());

const colors = {
  shade6: "#D3F3FF",
  shade5: "#C4E8F6",
  shade4: "#A5D1E6",

  shade0: "#04090b",
};

const {
  cachingKey,
  collection,
  initialQuery,
  copyFromProfile,
  copyFromProfileMap,
  copyForMentee,
  copyForMenteeMap,
} = config;

// const fields = [
//   { name: "value", label: "Enter Abhyasi ID or mobile or email" },
// ];

function NewRecord({ onDiscard }) {
  const firestore = useFirestore();
  const { srcmProfile = {} } = useAuth();
  const { userFirebaseUid: uid, srcm_group_id } = srcmProfile;
  const dispatch = useDispatch();

  const [emi, setEmi] = useState("abhyasiid");

  const dbRecords = useSelector((state) =>
    cachingKey in state.firestore.ordered
      ? state.firestore.ordered[cachingKey]
      : undefined
  );

  const { isFetching, result, errorMessage, refresh, reset } =
    useSearchAbhyasiEMI(`abhyasisemi-search-trainerm`);
  const [value, setValue] = useState("");
  const [notes, setNotes] = useState("");
  const [valid, setValid] = useState(false);
  const clearValue = useCallback(() => {
    setValue("");
    setValid(false);
    reset();
  }, [setValue, setValid, reset]);
  const zone = useZoneForCenter({ centerId: result?.srcm_group?.id });
  const mentor_zone = useZoneForCenter({ centerId: srcm_group_id });

  const onNewAdd = useCallback(() => {
    const mentorData = copyKeys(
      srcmProfile,
      copyFromProfile,
      copyFromProfileMap
    );

    const menteeData = copyKeys(fget(result), copyForMentee, copyForMenteeMap);

    if (
      dbRecords.some(
        (r) => r.mentee_abhyasi_id === menteeData.mentee_abhyasi_id
      )
    ) {
      dispatch(showAlert({ message: strings.dup_mentee }));

      clearValue();
      return;
    }

    firestore
      .add(collection, {
        ...mentorData,
        ...menteeData,
        uid,
        date_added: today,
        notes,
        mentee_zone: zone?.name,
        mentor_zone: mentor_zone?.name,
      })
      .then((/* docRef */) => {
        onDiscard();
      })
      .catch((e) => {
        dispatch(showAlert({ message: e.toString() }));
      });
  }, [
    uid,
    firestore,
    result,
    notes,
    clearValue,
    dbRecords,
    dispatch,
    onDiscard,
    srcmProfile,
    zone,
    mentor_zone,
  ]);

  const onSearch = useCallback(() => {
    if (value) {
      if (validateAbhyasiId(value.toUpperCase())) {
        refresh("ref", value.toUpperCase());
      } else if (validatePhoneNumber(value)) {
        refresh("mobile", value);
      } else if (validateEmail(value.toLowerCase())) {
        refresh("email", value.toLowerCase());
      }
    }
  }, [value, refresh]);

  const updateValue = useCallback(
    (v) => {
      if (emi === "email") {
        setValue(v.toLowerCase());
        if (validateEmail(v)) {
          setValid(true);
          return;
        }
      } else if (emi === "abhyasiid") {
        setValue(v.toUpperCase());
        if (validateAbhyasiId(v)) {
          setValid(true);
          return;
        }
      } else if (emi === "phone") {
        setValue(`+${v.replace(/\D/g, "")}`);
        if (validatePhoneNumber(v) && v[0] === "+" && v.length > 11) {
          setValid(true);
          return;
        }
      }

      setValid(false);
    },
    [setValid, setValue, emi]
  );

  useEffect(() => {
    setValue("");
  }, [emi, setValue]);

  return (
    <div sx={{ p: 3, my: 2, backgroundColor: colors.shade6 }}>
      <Text variant="title">{strings.form_title}</Text>

      {!result && (
        <div>
          <Text variant="subtitle">{strings.label_search}</Text>
          <br />
          <Stack horizontal>
            <DynamicField
              type="select"
              name="emi"
              label=""
              value={emi}
              onChange={setEmi}
              options={[
                // { name: "email", label: "Email" },
                // { name: "phone", label: "Mobile" },
                { name: "abhyasiid", label: "Abhyasi ID" },
              ]}
            />
            <DynamicField
              props={{ autoFocus: true }}
              name="value"
              value={value}
              label=""
              onChange={updateValue}
              type={emi}
            />
            <DefaultButton
              disabled={!valid}
              iconProps={{ iconName: "Search" }}
              text={strings.btn_search}
              onClick={onSearch}
            />
          </Stack>
          {emi === "phone" && (
            <Text variant="description">
              Please enter phone in format: +919900990099
            </Text>
          )}
        </div>
      )}

      {isFetching && <Spinner />}
      {errorMessage && <Alert sx={{ my: 1 }}> {errorMessage} </Alert>}

      {result && (
        <div>
          <div style={containerStyles.flexcenter}>
            <Stack tokens={{ childrenGap: 10 }}>
              <div>
                <div
                  sx={{ border: "solid 1px", p: 2, borderColor: colors.shade4 }}
                >
                  {renderAbhyasiWithPhoto(result)}
                  <div sx={{ p: 1, backgroundColor: colors.shade5 }}>
                    Center: {result?.srcm_group?.name}
                    <br />
                    Zone: {zone?.name}
                  </div>
                </div>
              </div>
              <DynamicField
                name="notes"
                label={strings.notes}
                type="text"
                props={{ multiline: true, rows: 6 }}
                value={notes}
                onChange={setNotes}
              />
            </Stack>
          </div>
          <div sx={{ my: 3 }} style={containerStyles.flexcenter}>
            <DefaultButton
              sx={{ mr: 3 }}
              iconProps={{ iconName: "Cancel" }}
              text={strings.btn_change}
              onClick={clearValue}
            />
            <PrimaryButton
              iconProps={{ iconName: "Accept" }}
              text={strings.btn_accept}
              onClick={onNewAdd}
            />
          </div>
        </div>
      )}
    </div>
  );
}

NewRecord.propTypes = {
  onDiscard: PropTypes.func,
};
NewRecord.defaultProps = {
  onDiscard: noop,
};

function MyRecords() {
  const { srcmProfile = {} } = useAuth();

  const { userFirebaseUid: uid } = srcmProfile;

  const dbRecords = useSelector((state) =>
    cachingKey in state.firestore.ordered
      ? state.firestore.ordered[cachingKey]
      : undefined
  );
  const firestore = useFirestore();
  const [limit, setLimit] = useState(10);

  // const onSave = useCallback(
  //   (
  //     op,
  //     pk_key,
  //     pk_val,
  //     new_data,
  //     old_data,
  //     success_callback,
  //     failure_callback
  //   ) => {
  //     return firestore
  //       .set(`${collection(uid)}/${pk_val}`, new_data)
  //       .then(success_callback)
  //       .catch((e) => failure_callback(e.toString()));
  //   },
  //   [firestore, uid]
  // );

  useEffect(() => {
    const listenerSettings = {
      ...initialQuery({ uid }),
      storeAs: cachingKey,
      limit,
    };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore, uid, limit]);

  const showMore = dbRecords ? dbRecords.length === limit : false;
  const incr10 = useCallback(() => setLimit((a) => a + 10), []);

  const onDelete = useCallback(
    (r) => {
      firestore.delete(`${collection}/${r.id}`);
    },
    [firestore]
  );

  return (
    <div sx={{ mb: 3 }}>
      {dbRecords === undefined && <Spinner />}
      {dbRecords !== undefined && (
        <div>
          {dbRecords.length === 0 && (
            <div sx={{ textAlign: "center", m: 2 }}>
              <Text variant="description"> {strings.no_mentees} </Text>
            </div>
          )}
          {dbRecords.length > 0 && (
            <div>
              <Table style={{ width: "100%" }}>
                <tbody>
                  <TableRow>
                    <TableCell>
                      <Text variant="subtitle">{strings.date_added}</Text>
                    </TableCell>
                    <TableCell>
                      <Text variant="subtitle">{strings.mentee}</Text>
                    </TableCell>
                    <TableCell>
                      <Text variant="subtitle">{strings.city}</Text>
                    </TableCell>
                    <TableCell>
                      <Text variant="subtitle">{strings.notes}</Text>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  {dbRecords.map((r) => {
                    return (
                      <TableRow key={r.id}>
                        <TableCell>{doFormatShortDate(r.date_added)}</TableCell>
                        <TableCell>
                          <AbhyasiEntity
                            abhyasiId={r.mentee_abhyasi_id}
                            display={
                              !r.mentee_name ? (
                                r.mentee_abhyasi_id
                              ) : (
                                <span>
                                  <Text variant="printFormValue">
                                    {r.mentee_name}
                                  </Text>
                                </span>
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Text variant="printFormValue">
                            {r.mentee_city},{" "}
                            {r.mentee_country === "India"
                              ? r.mentee_state
                              : r.mentee_country}
                          </Text>
                        </TableCell>
                        <TableCell>{r.notes}</TableCell>
                        <TableCell>
                          <ConfirmButton
                            title={`Deleting ${r.mentee_name} as mentee`}
                            onClick={() => onDelete(r)}
                          >
                            <IconButton iconProps={{ iconName: "Trash" }} />
                          </ConfirmButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </tbody>
              </Table>
              {showMore && (
                <ActionButton text="show more entries ..." onClick={incr10} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function RecordsApp() {
  const [add, { setTrue: doShowAdd, setFalse: doHideAdd }] =
    useMyBoolean(false);
  return (
    <div>
      <div sx={{ textAlign: "center", m: 2 }}>
        <Text variant="header">{strings.title}</Text>
        <br />
        <Text variant="subtitle">{strings.subtitle}</Text>
        <hr />
      </div>

      <div>
        <div sx={{ mb: 3 }}>
          <MyRecords />

          {!add && (
            <div>
              <DefaultButton
                iconProps={{ iconName: "Add" }}
                text={strings.btn_add_abhyasi}
                onClick={doShowAdd}
              />
            </div>
          )}
          {add && (
            <div>
              <NewRecord onDiscard={doHideAdd} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecordsApp;
