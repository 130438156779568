/** @jsx jsx */
import { jsx } from "theme-ui";
import DLWLayout from "../../../layouts/dlw-fixed";

import AbhyasiMentoring from "../../../components/Trainer/AbhyasiMentoring";

function SpecialExercise() {
  return (
    <DLWLayout>
      <AbhyasiMentoring />
    </DLWLayout>
  );
}

export default SpecialExercise;
