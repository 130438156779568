import React from "react";

import PropTypes from "prop-types";

const PS = {
  style: PropTypes.shape({}),
};
const PD = {
  style: {},
};

export const Table = ({ style, ...restprops }) => (
  <div style={{ overflowX: "auto" }}>
    <div {...restprops} style={{ ...(style || {}), display: "table" }} />
  </div>
);
export const TableRow = ({ style, ...restprops }) => (
  <div {...restprops} style={{ ...(style || {}), display: "table-row" }} />
);
export const TableCell = ({ style, ...restprops }) => (
  <div
    {...restprops}
    style={{
      ...(style || {}),
      display: "table-cell",
      border: "1px solid #eee",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 4,
      paddingBottom: 4,
    }}
  />
);
export const TableHead = ({ style, ...restprops }) => (
  <div
    {...restprops}
    style={{
      ...(style || {}),
      display: "table-cell",
      border: "1px solid #eee",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 4,
      paddingBottom: 4,
      fontWeight: 700,
    }}
  />
);

Table.propTypes = PS;
TableHead.propTypes = PS;
TableRow.propTypes = PS;
TableCell.propTypes = PS;

Table.defaultProps = PD;
TableHead.defaultProps = PD;
TableRow.defaultProps = PD;
TableCell.defaultProps = PD;
